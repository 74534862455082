import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { serverTokenUrl, serverUrl, edcUrl, innovitiPaymentCodes } from "../../../constants/config";
import { clock, timeStamp, timeValidator, currentDay } from "../../../utility/clock";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../lib/auth";
import { v4 as uuidv4 } from "uuid";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import db from "../../../database";
import Axios from "axios";

// OMS Orders Modal Imports //
import NewWhite from "../../../assets/images/pending.svg";
import PreparingWhite from "../../../assets/images/parkedOrder.svg";
import ReadyWhite from "../../../assets/images/completed.svg";
import CompletedWhite from "../../../assets/images/prepared.svg";
import New from "../../../assets/images/new.svg";
import Preparing from "../../../assets/images/preparing.svg";
import Ready from "../../../assets/images/ready.svg";
import Completed from "../../../assets/images/todayOrders.svg";
import _ from "lodash";
import CoreModals from "./coreModals";
import ReturnBill from "./returnBill";

// PointOfsaleCore Component Start
const PointOfsaleCore = (props) => {
  const RenderComponent = props.component;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  // const defaultCustomer = JSON.parse(localStorage.getItem("defaultCustomer"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
  const tillDataPaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const history = useHistory();
  const qs = require("querystring");
  const { setAuthTokens } = useAuth();

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // ORDER TYPE BLOCK START
  const [orderType, setOrderType] = useState();
  const [posSaleTypes, setPosSaleTypes] = useState([]);
  const [displaySetOrderType, setDisplayOrderType] = useState(false);
  const changeOrderType = (type) => {
    setDisplayOrderType(false);
    setOrderType(type);
  };
  useEffect(() => {
    db.posSaletypes.toArray().then((saleType) => {
      setPosSaleTypes([...saleType]);
      const saleIndex = saleType.findIndex((st) => st.cwrSaletype.isdefault === "Y");
      setOrderType(saleType[saleIndex]);
    });
  }, []);
  // ORDER TYPE BLOCK END

  // CUSTOMER  SEARCH AND SELECTION BLOCK START
  const [displayCustomerSearch, setDisplayCustomerSearch] = useState(false);
  const [customerSearchType, setCustomerSearchType] = useState(() => (posConfig.defaultCustomerSearch === "Search Key" ? "searchKey" : "mobile"));
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [customerSearchResults, setCustomerSearchResults] = useState();

  const closeCustomerSearch = () => {
    setDisplayCustomerSearch(false);
    setCustomerSearchInput("");
    setCustomerSearchResults();
    console.log("paymentModalByCustomerState", paymentModalByCustomerState);
    if (paymentModalByCustomerState) {
      openPaymentModalByCustomer();
    }
  };

  const handleCustomerSearch = () => {
    let query = `mobileNo:"${customerSearchInput}"`;
    let validation = RegExp("^[6-9][0-9]{9}$");

    if (customerSearchType !== "mobile") {
      query = `searchKey:"${customerSearchInput}"`;
      validation = RegExp("^[0-9a-zA-Z]{1,20}$");
    }

    if (validation.test(parseFloat(customerSearchInput))) {
      const paramsInput = {
        query: `query{
          customerSearch(${query}){
            cwrCustomerId 
            code   
            name
            email        
            mobileNo
            name
            pincode
            retlLoyaltyBalance
            b2cRegisteredstoreId
             iscredit
            balancepoints
            loyaltyLevel{
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
            }
            sCustomer{
              sCustomerID
              customerCategory{
                sCustomerCateforyId
                value
                name
                description
              }
            }
            csBunit{
              csBunitId
              name
            }
          }
        }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      })
        .then((response) => {
          if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
            const result = response.data.data.customerSearch;
            setCustomerSearchResults(result);
          } else {
            console.warn("No customer found, try searching with different customer");
            setCustomerSearchResults([]);
          }
        })
        .catch((error) => {
          console.error("Customer search:", error);
          const err = JSON.parse(JSON.stringify(error));
          const { message } = err;
          if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
            const refToken = tokens.refresh_token;
            const requestBody = {
              grant_type: "refresh_token",
              refresh_token: refToken,
            };
            const config = {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
              },
            };
            Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
              setAuthTokens(newToken.data);
            });
          }
        });
    } else {
      console.warn("Invalid customer search input");
      message.warning("Invalid Customer Search Input");
    }
  };

  const selectCustomer = (index) => {
    setCart({
      ...cart,
      customer: customerSearchResults[index],
    });
    closeCustomerSearch();
  };
  // CUSTOMER  SEARCH AND SELECTION BLOCK END

  // ADD NEW CUSTOMER BLOCK START
  const [form] = Form.useForm();
  const [displayAddNewCustomer, setDisplayAddNewCustomer] = useState(false);

  const showAddNewCustomerFields = () => {
    setDisplayCustomerSearch(false);
    if (customerSearchType === "mobile") {
      form.setFieldsValue({
        mobile: customerSearchInput,
      });
    } else {
      form.setFieldsValue({
        name: customerSearchInput,
      });
    }
    setDisplayAddNewCustomer(true);
  };

  const addNewCustomer = (data) => {
    const checkMobNo = data.mobile;
    const paramsInput = {
      query: `query {
            customerSearch(mobileNo: "${checkMobNo}") {
              mobileNo          
            }
          }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    })
      .then((response) => {
        if (response.data.data.customerSearch.length === 0) {
          const newCustomerId = uuidv4().replace(/-/g, "").toUpperCase();
          const newRequest = {
            query: `mutation {
              upsertB2CCustomer(
                id: "${newCustomerId}"
                type: "New"
                customer: {
                  csBunitId: "${tillData.tillAccess.csBunit.csBunitId}",
                  createdby: "${tillData.tillAccess.csUserId}",
                  updatedby: "${tillData.tillAccess.csUserId}",
                  code: "${data.name}",
                  name: "${data.name}",
                  mobileNo: "${data.mobile}",
                  email: ${data.email ? `"${data.email}"` : null},
                  pincode: ${data.pincode ? data.pincode : null},
                  sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
			            finPaymentmethodId: null,
			            retlLoyaltyBalance: null,
			            iscredit: false,
			            b2cRegisteredstoreId: "${tillData.tillAccess.csBunit.csBunitId}",
			            loyaltyLevel: { cwrLoyaltyLevelId:  ${posConfig.addLoyaltyLevel === "Y" ? `"${posConfig.loyaltyLevelID}"` : null} }
                }
              ) {
                cwrCustomerId
                code
                name
                email
                mobileNo
                name
                pincode
                retlLoyaltyBalance
                b2cRegisteredstoreId
                iscredit
                balancepoints
                loyaltyLevel {
                  cwrLoyaltyLevelId
                  name
                  accumulationRate
                  redemptionRate
                }
                sCustomer {
                  sCustomerID
                  customerCategory {
                    sCustomerCateforyId
                    value
                    name
                    description
                  }
                }
                csBunit {
                  csBunitId
                  name
                }
                status
                message
              }
            }`,
          };
          Axios({
            url: serverUrl,
            method: "POST",
            data: newRequest,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          })
            .then((response) => {
              const result = response.data.data.upsertB2CCustomer;
              if (result.status === "200") {
                form.resetFields();
                setDisplayAddNewCustomer(false);
                setCart({
                  ...cart,
                  customer: result,
                });
                closeCustomerSearch();
                message.success(result.message);
                if (
                  db.logConfiguration.toArray().then((fetched) => {
                    fetched.map((item) => {
                      item.addCustomer.filter((item) => item.log === "Y").length > 0;
                    });
                  })
                ) {
                  posLogActivity(result.name, "ACT");
                }
              } else {
                message.error("Some thing went wrong try again later !");
              }
            })
            .catch((error) => {
              console.error("Adding a new customer failed: ", error);
              message.error("Some thing went wrong try again later !");
            });
        }
      })
      .catch((error) => {
        console.error("Search for existnig customer failed: ", error);
        message.error("Some thing went wrong try again later !");
      });
  };
  // ADD NEW CUSTOMER BLOCK END

  // EDIT CUSTOMER BLOCK END
  const [displayEditOldCustomer, setDisplayEditOldCustomer] = useState(false);
  const [selectedEditOldCustomer, setSelectedEditOldCustomer] = useState();

  const showEditOldCustomerFields = (customer) => {
    setDisplayCustomerSearch(false);
    setSelectedEditOldCustomer(customer);
    form.setFieldsValue({
      editName: customer.name,
      editMobile: customer.mobileNo,
      editEmail: customer.email,
      editPincode: customer.pincode,
      name: "Name",
      mobile: 9999999999,
    });
    setDisplayEditOldCustomer(true);
  };

  const editOldCustomer = (data) => {
    const newRequest = {
      query: `mutation{
            upsertB2CCustomer(id: "${selectedEditOldCustomer.cwrCustomerId}", 
              type:"Old",
              customer:{
                name: "${data.editName}"
                mobileNo: "${data.editMobile}"
                email: ${data.editEmail ? `"${data.editEmail}"` : null},
                pincode: ${data.editPincode ? data.editPincode : null},
            }){
              cwrCustomerId
              code
              name
              email
              mobileNo
              name
              pincode
              retlLoyaltyBalance
              b2cRegisteredstoreId
              iscredit
              balancepoints
              loyaltyLevel {
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
              }
              sCustomer {
                sCustomerID
                customerCategory {
                  sCustomerCateforyId
                  value
                  name
                  description
                }
              }
              csBunit {
                csBunitId
                name
              }
              status
              message
            }
          }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: newRequest,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    })
      .then((response) => {
        const result = response.data.data.upsertB2CCustomer;
        if (result.status === "200") {
          form.resetFields();
          setSelectedEditOldCustomer();
          setDisplayEditOldCustomer(false);
          setCart({
            ...cart,
            customer: result,
          });
          closeCustomerSearch();
          message.success(result.message);
        } else {
          message.error("Some thing went wrong try again later !");
        }
      })
      .catch((error) => {
        console.error("Updating customer failed: ", error);
        message.error("Some thing went wrong try again later !");
      });
  };
  // EDIT CUSTOMER BLOCK END

  //// CENTER BUTTON BLOCK START /////

  const [isQtyUpdate, setIsQtyUpdate] = useState(false);

  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectProductInCart = (record) => {
    setSelectedRowKeys([record.key]);
    setSelectedProductInCart(record);
  };

  const deleteProduct = (addToCart) => {
    if (!addToCart.isReturn) {
      addProduct(addToCart, -addToCart.weight);
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.deleteLines.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        posLogActivity(addToCart, "DLN");
      }
    }
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        addProduct(addToCart, -1);
        if (
          db.logConfiguration.toArray().then((fetched) => {
            fetched.map((item) => {
              item.decreaseQty.filter((item) => item.log === "Y").length > 0;
            });
          })
        ) {
          posLogActivity(addToCart, "RQT");
        }
      }
    }
  };

  const increaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        addProduct(addToCart, 1);
      }
    }
  };

  const deleteReceipt = () => {
    setCart({
      items: [],
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: defaultCustomer,
      salesRepId: null,
    });
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    setAmount("");
    setSelectedPaymentMethod("");
  };

  const deleteCart = (status = false) => {
    if (status === true) {
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.deleteOrder.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        posLogActivity(cart.items, "DOR");
      }
    }
    deleteReceipt();
    /* if (parkedList.length > 0) {
      selectParkedBill(parkedList[0]);
    } */
  };

  // ORDER HISTORY BLOCK START
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);

  const [orderHistorySearchType, setOrderHistorySearchType] = useState("orderDocumentNo");
  const changeOrderHistorySearchType = (value) => {
    setOrderHistorySearchType(value);
  };

  const showOrderHistory = () => {
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        setOrderHistoryDetails([...data]);
        setDisplayOrderHistory(true);
      });
  };

  const [selectedOrderHistoryLine, setSelectedOrderHistoryLine] = useState("");
  const showOrderHistoryLine = (orderID) => {
    if (selectedOrderHistoryLine === orderID) {
      setSelectedOrderHistoryLine("");
    } else {
      setSelectedOrderHistoryLine(orderID);
    }
  };

  const [orderHistoryInput, setOrderHistoryInput] = useState("");
  const searchOrderHistory = () => {
    let indexType = "documentno";
    if (orderHistorySearchType === "orderDocumentNo") {
      indexType = "documentno";
    }

    if (orderHistorySearchType === "orderCustomerSearchKey") {
      indexType = "customerSearchKey";
    }

    db.orders
      .where(indexType)
      .startsWithIgnoreCase(orderHistoryInput)
      .limit(20)
      .toArray()
      .then((data) => {
        setOrderHistoryDetails([...data]);
      });
    setOrderHistoryInput("");
  };

  // ORDER HISTORY BLOCK END

  // PARKED BILL BLOCK START
  const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
  const initialParkedList = storedParkedList ? storedParkedList : [];
  const [displayParkedBillModal, setDisplayParkedBillModal] = useState(false);
  const [parkedList, setParkedList] = useState(initialParkedList);
  const [filterdParkedList, setFilterdParkedList] = useState(initialParkedList);

  useEffect(() => {
    setFilterdParkedList(parkedList);
  }, [parkedList]);

  const openDisplayParkedBillModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Items in cart might lost.
            <br />
            Please save in order to retrive later
          </div>
        ),
        okText: "Save",
        cancelText: "No",
        onOk() {
          parkBill();
          // message.info("Please wait...");
          setTimeout(() => {
            setDisplayParkedBillModal(true);
          }, 2000);
        },
        onCancel() {
          setDisplayParkedBillModal(true);
        },
      });
    } else {
      setDisplayParkedBillModal(true);
    }
  };

  const parkedListRef = useRef(initialParkedList);
  useEffect(() => {
    parkedListRef.current = parkedList;
  }, [parkedList]);

  const parkBill = (value) => {
    const presentParkedList = value === "parkKey" ? parkedListRef.current : parkedList;
    const presentCart = cart;
    const presentTimeStamp = timeStamp();

    const parkedBill = {
      parkedCart: presentCart,
      parkedTime: presentTimeStamp,
      parkedBillId: uuidv4().replace(/-/g, "").toUpperCase(),
    };
    presentParkedList.push(parkedBill);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    deleteReceipt();
    message.success("Bill Parked Successfully");
  };

  const selectParkedBill = (item) => {
    const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
    const selectedParkedBill = parkedList[listItemIndex].parkedCart;
    const presentParkedList = parkedList;
    presentParkedList.splice(listItemIndex, 1);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    setCart(selectedParkedBill);
    setDisplayParkedBillModal(false);
  };

  const [parkedBillSearchInput, setParkedBillSearchInput] = useState("");

  const handleParkedBillSearchInput = (e) => {
    setParkedBillSearchInput(e.target.value);
  };

  const searchParkedBill = () => {
    const serchResults = parkedList.filter((list) => list.parkedCart.customer.code.toLowerCase() === parkedBillSearchInput.toLowerCase());
    if (parkedBillSearchInput.length > 1) {
      setFilterdParkedList([...serchResults]);
    } else {
      setFilterdParkedList([...parkedList]);
    }
    setParkedBillSearchInput("");
  };

  const closeParkedBillModal = () => {
    setDisplayParkedBillModal(false);
    setParkedBillSearchInput("");
    setFilterdParkedList([...parkedList]);
  };

  // PARKED BILL BLOCK END

  //// CENTER BUTTON BLOCK END ////

  //// CART OPERATIONS START ////

  // DEFAULT CART START
  const [cart, setCart] = useState({
    items: [],
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
    salesRepId: null,
  });
  // DEFAULT CART END

  // BARCODE READER BLOCK START
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const onBarcodeInput = (data) => {
    db.products
      .where("upcIndex")
      .equalsIgnoreCase(data)
      .toArray()
      .then((product) => {
        if (product.length > 0) {
          const obj = product[0];
          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === data.toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                obj.mBatch[batchProductIndex].upc,
                obj.mBatch[batchProductIndex].batchno,
                obj.mBatch[batchProductIndex].mBatchId,
                obj.mBatch[batchProductIndex].price
              );
            } else if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              const localObj = obj;
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = { ...localObj.mBatch[i] };
                const obj = { ...localObj };
                if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                  // prettier-ignore
                  const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                  const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                  batchItem.price = taxedPrice;
                  obj.cTaxId = obj.contraTaxId;
                  obj.taxRate = obj.contraRate;
                }
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: obj.cTaxId,
                  returnQty: null,
                  salePrice: obj.cTaxId,
                  mrpPrice: batchItem.listPrice,
                  stock: obj.onhandQty,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  uom_name: obj.uomName,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                  batchedProduct: obj.batchedProduct,
                  batchedForSale: obj.batchedForSale,
                  batchedForStock: obj.batchedForStock,
                  multiPrice: obj.multiPrice,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
          }
        } else {
          message.warning("Product Not Found !");
        }
      });
  };

  const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      weight: 0,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      productCategoryName: productObj?.productCategoryName || "",
      productAddons: productObj?.productAddons || [],
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
    };
    checkIsManualWeight(productDefined);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };
  // BARCODE READER BLOCK END

  // PRODUCT WEIGHT MODAL START
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const onProductModalChangeWeight = (event) => {
    setProductWeightModalInput(event.target.value);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
      if (obj.mBatch.length === 1) {
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        const localObj = obj;
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = { ...localObj.mBatch[i] };
          const obj = { ...localObj };
          if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
            // prettier-ignore
            const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
            const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
            batchItem.price = taxedPrice;
            obj.cTaxId = obj.contraTaxId;
            obj.taxRate = obj.contraRate;
          }
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description,
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl,
            isDecimal: obj.isDecimal,
            isManualQty: obj.isManualQty,
            isPromoApplicable: obj.isPromoApplicable,
            isReturn: false,
            mBatchId: batchItem.mBatchId,
            mPricingruleId: null,
            name: obj.name,
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId,
            realPrice: batchItem.price,
            returnQty: null,
            salePrice: batchItem.price,
            mrpPrice: batchItem.listPrice,
            stock: obj.onhandQty,
            tax: obj.cTaxId,
            taxAmount: 0,
            taxRate: obj.taxRate,
            uom: obj.csUomId,
            uom_name: obj.uomName,
            upc: batchItem.upc,
            value: obj.value,
            weight: 0,
            shortDescription: obj.shortDescription,
            hsncode: obj.hsncode,
            csBunitId: obj.csBunitId,
            mProductCategoryId: obj.mProductCategoryId,
            productManufacturerId: obj.productManufacturerId,
            productBrandId: obj.productBrandId,
            batchedProduct: obj.batchedProduct,
            batchedForSale: obj.batchedForSale,
            batchedForStock: obj.batchedForStock,
            multiPrice: obj.multiPrice,
          };
          productSet.push(productDefined);
        }
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const checkIsManualWeight = (prod) => {
    if (prod.isManualQty && posConfig.showWeightPopup === "Y") {
      setDisplayManualQtyWeightInput(true);
      setCurrentWeightSelectedProduct(prod);
    } else {
      addProduct(prod, 1);
    }
  };

  const addManualWeightToProduct = () => {
    let inputValidator = new RegExp(/^[0-9]*$/);
    if (currentWeightSelectedProduct.isDecimal) inputValidator = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    if (inputValidator.test(productWeightModalInput) && parseFloat(productWeightModalInput) > 0) {
      setDisplayManualQtyWeightInput(false);
      addProduct(currentWeightSelectedProduct, productWeightModalInput);
      setTimeout(() => {
        setProductWeightModalInput("");
        setCurrentWeightSelectedProduct({});
      }, 500);
    } else {
      console.warn("Invalid weight input: ", productWeightModalInput);
      message.warning("Invalid input value");
    }
  };

  // PRODUCT WEIGHT MODAL END

  // PRODUCT FILTER START
  const [isProductsFilter, setIsProductsFilter] = useState(() => (posConfig.defaultSearchScreen === "Category Search" ? true : false));
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("allProducts");
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    db.productCategories.toArray().then((res) => {
      setProductCategories([...res]);
    });
  }, []);

  useEffect(() => {
    getCategoryProducts(selectedProductCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProductCategory = (category) => {
    if (selectedProductCategory !== category) {
      setSelectedProductCategory(category);
      setProductsList([]);
    }
  };

  useEffect(() => {
    if (selectedProductCategory !== "all") {
      getCategoryProducts();
    }
  }, [selectedProductCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMoreProducts = () => {
    if (productSearchInput === "") {
      getCategoryProducts(selectedProductCategory);
    }
  };

  const getCategoryProducts = () => {
    if (selectedProductCategory === "allProducts") {
      db.products
        .offset(productsList.length)
        .limit(100)
        .toArray()
        .then((productsFetched) => {
          const additionalProductsFetched = productsList.concat(productsFetched);
          setProductsList([...additionalProductsFetched]);
        });
    } else {
      db.products
        .where("mProductCategoryId")
        .equalsIgnoreCase(selectedProductCategory)
        .offset(productsList.length)
        .limit(100)
        .toArray()
        .then((productsFetched) => {
          const additionalProductsFetched = productsList.concat(productsFetched);
          setProductsList([...additionalProductsFetched]);
        });
    }
  };
  // PRODUCT FILTER END

  // PRODUCT SEARCH START
  const [isSearchProducts, setIsSearchProducts] = useState(() =>
    posConfig.defaultSearchScreen === "Product Search" || posConfig.defaultSearchScreen === "Category Search" ? true : false
  );
  const [productSearchInput, setProductSearchInput] = useState("");

  /* useEffect(() => {
    if (productSearchInput !== "" && productSearchInput.length >= 3) {
      getSearchedProducts();
    }
  }, [productSearchInput]); */ // eslint-disable-line

  const getSearchedProducts = () => {
    db.products
      .where("name")
      .startsWithIgnoreCase(productSearchInput)
      .or("batchIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("upcIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("value")
      .equalsIgnoreCase(productSearchInput)
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        setProductsList([...productsFetched]);
        productListCardRef.current.focus();
      });
  };

  const clearProductSearchResults = () => {
    setProductSearchInput("");
    setProductsList([]);
    if (selectedProductCategory !== "allProducts") {
      setSelectedProductCategory("allProducts");
    } else {
      setSelectedProductCategory("all");
      setTimeout(() => {
        setSelectedProductCategory("allProducts");
      }, 200);
    }
  };

  const productSearchInputRef = useRef(null);
  const productListCardRef = useRef(null);

  const closeProductPanel = () => {
    setIsSearchProducts(false);
    setIsProductsFilter(false);
    setProductSearchInput("");
    clearProductSearchResults();
    productSearchInputRef.current.blur();
  };
  // PRODUCT SEARCH END

  const addProduct = (addToCart, qty) => {
    setProductSearchInput("");
    clearProductSearchResults();
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    const index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && p.isReturn === false);
    /* console.log("newLocated index", index);
    console.log("newLocated addToCart", addToCart);
    console.log("newLocated condition", !addedToCart?.[index]?.parkedItem, index >= 0 && !addedToCart?.[index]?.parkedItem); */

    if (index >= 0 && !addedToCart?.[index]?.parkedItem) {
      if (weight > 0) {
        message.success(`${addedToCart[index].name} Added Successfully`);
      }
      addedToCart[index].weight = addedToCart[index].weight + weight;
      if (addedToCart[index].weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        const mrp = parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        const presentProduct = addedToCart[index];
        addedToCart.splice(index, 1);
        addedToCart.unshift(presentProduct);
        addedToCart.reverse();
      }
    } else {
      if (weight !== 0) {
        addToCart.weight = weight;
        const mrp = parseFloat(addToCart.salePrice) * addToCart.weight;
        const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
        addToCart.taxAmount = tax;
        addToCart.nettotal = mrp;
        addToCart.salesRepId = salesRepresentDefaultLine.salesRepresentId;
        addToCart.salesRepName = salesRepresentDefaultLine.name;
        addedToCart.push(addToCart);
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    addedToCart.reverse();

    for (let i = 0; i < addedToCart.length; i += 1) {
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    setCart({
      ...cart,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    });
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    checkProductDiscounts(addToCart);
    if (cart.manualDiscountApplied && cart.manualDiscountApplied !== 0) {
      setLoader(true);
      setTimeout(() => {
        processTotalManualDiscount(cart.manualDiscountApplied);
        setLoader(false);
      }, 2000);
    }
  };

  const checkProductDiscounts = (product) => {
    if (product.isPromoApplicable) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (timeValidator(pricingRule.startDate, pricingRule.endDate)) {
              if (pricingRule.timeSpecific === "Y") {
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime.substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                const weekDay = currentDay();
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processProductDiscounts(product, pricingRule);
                }
              } else {
                processProductDiscounts(product, pricingRule);
              }
            }
          }
        }
      });
    }
  };

  const processProductDiscounts = (product, pricingRule) => {
    if (pricingRule.type === "FD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          if (addedToCart[index].weight >= pricingRule.minimumQty) {
            const discountAmt = pricingRule.amountDiscount;
            const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nextRule = pricingRule.nextRule;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nextRule;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "PD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          if (addedToCart[index].weight >= pricingRule.minimumQty) {
            const discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart[index].realPrice);
            const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nextRule = pricingRule.nextRule;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nextRule;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "QD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
        const offerIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
        if (offerIndex >= 0 && index >= 0) {
          let discountAmt = 0;
          if (pricingRule.mPricingQuantities.length >= 0) {
            pricingRule.mPricingQuantities.map((qd) => {
              if (parseFloat(qd.quantity) === parseFloat(addedToCart[index].weight)) {
                if (pricingRule.discountType === "V") {
                  discountAmt = parseFloat(qd.discountValue);
                } else {
                  discountAmt = (qd.discountValue / 100) * parseFloat(addedToCart[index].realPrice);
                }
              }
              return null;
            });
          }

          if (discountAmt > 0) {
            const sp = (parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight - discountAmt) / addedToCart[index].weight;
            const mrp = parseFloat(sp) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].salePrice = sp;
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = discountAmt * addedToCart[index].weight;
            addedToCart[index].discountName = pricingRule.printedName;
            addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
            addedToCart[index].nextRule = pricingRule.nextRule;
            // message.success(pricingRule.printedName);
          } else {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            delete addedToCart[index].nextRule;
          }

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;
          totalPrice = roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        }
      }
    }

    if (pricingRule.type === "PK" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId);
      }

      if (pricingRule.mPricingBrands.length > 0) {
        discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId);
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }

      if (saleTypeValidation & discountValidator) {
        const addedToCart = cart.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const prodIndexes = [];
        const properWeights = [];
        let productsPriceTotal = 0;

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
          if (ifExistis >= 0) {
            prodIndexes.push(index);
            properWeights.push(item.weight);
            productsPriceTotal += parseFloat(item.realPrice);
          }
          return null;
        });

        if (prodIndexes.length === offerProducts.length) {
          const distributedAmount = parseFloat(pricingRule.fixedUnitPrice) / prodIndexes.length;
          const distributedDiscount = (productsPriceTotal - distributedAmount) / prodIndexes.length;
          if (properWeights.every((item) => item === properWeights[0])) {
            prodIndexes.map((index) => {
              const sp = distributedAmount;
              const mrp = parseFloat(sp) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].salePrice = sp;
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = distributedDiscount;
              addedToCart[index].discountName = pricingRule.printedName;
              addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
              // message.success(pricingRule.printedName);
              return null;
            });
          } else {
            prodIndexes.map((index) => {
              const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = 0;
              addedToCart[index].discountName = "";
              addedToCart[index].mPricingruleId = null;
              return null;
            });
          }
        } else {
          prodIndexes.map((index) => {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            return null;
          });
        }

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }

    if (pricingRule.type === "XGY" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletypeId;
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length >= 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length >= 0) {
        return (discountValidator = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === product.mProductCategoryId));
      }

      if (pricingRule.mPricingBrands.length >= 0) {
        return (discountValidator = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === product.productBrandId));
      }

      if (pricingRule.mPricingBUnits.length >= 0) {
        return (discountValidator = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId));
      }

      if (saleTypeValidation && discountValidator) {
        const addedToCart = cart.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const freeProducts = pricingRule.mPricingXProducts.filter((prod) => prod.isFree === "Y");
        // const conditionalProducts = pricingRule.mPricingXProducts.filter((prod)=> prod.isFree === "N");
        const prodIndexes = [];
        let freeProductIndex;
        let minimumSet = 1;

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
          if (ifExistis >= 0) {
            if (item.productId === freeProducts[0].mProductId) {
              freeProductIndex = index;
            }
            if (parseFloat(item.weight) <= minimumSet) {
              minimumSet = parseFloat(item.weight);
            }
            prodIndexes.push(index);
          }
          return null;
        });

        if (prodIndexes.length === pricingRule.mPricingXProducts.length) {
          addedToCart[freeProductIndex].weight = minimumSet;
          const sp = 0;
          const mrp = sp * addedToCart[freeProductIndex].weight;
          const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
          addedToCart[freeProductIndex].salePrice = sp;
          addedToCart[freeProductIndex].taxAmount = tax;
          addedToCart[freeProductIndex].nettotal = mrp;
          addedToCart[freeProductIndex].discount = addedToCart[freeProductIndex].realPrice * addedToCart[freeProductIndex].weight;
          addedToCart[freeProductIndex].discountName = pricingRule.printedName;
          addedToCart[freeProductIndex].mPricingruleId = pricingRule.mPricingrulesId;
          // message.success(pricingRule.printedName);
        } else {
          const mrp = parseFloat(addedToCart[freeProductIndex].realPrice) * addedToCart[freeProductIndex].weight;
          const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
          addedToCart[freeProductIndex].taxAmount = tax;
          addedToCart[freeProductIndex].nettotal = mrp;
          addedToCart[freeProductIndex].discount = 0;
          addedToCart[freeProductIndex].discountName = "";
          addedToCart[freeProductIndex].mPricingruleId = null;
        }

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }
  };

  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);
  const [activeOrderProcess, setActiveOrderProcess] = useState(false);
  const orderState = useRef(0);

  const processOrder = (param) => {
    console.log("processOrder", orderState.current);
    orderState.current += 1;
    if (orderState.current === 1 && cart.items.length !== 0) {
      console.log("processOrderApproved", orderState.current);
      processOrderApproved(param);
    } else {
      console.log("setPaymentModal", orderState.current);
      setPaymentModal(false);
    }
  };

  const printReceiptData = (receiptData) => {
    if (isPrintModeXML) {
      xmlPrint(receiptData, "Receipt");
    } else {
      htmlPrint(receiptData, "Receipt");
    }
  };

  const processOrderApproved = (param) => {
    if (!activeOrderProcess) {
      setActiveOrderProcess(true);

      const cartToDb = cart;
      const newDocumentSequence = documentSequence + 1;

      const tillSession = JSON.parse(localStorage.getItem("tillSession"));
      const tillSessionId = tillSession.tillSessionId;

      if (param !== "layaway") {
        cartToDb.layAway = "N";
        const change = cartToDb.paid - cartToDb.total;

        if (cart.items.filter((it) => it.isReturn === true).length > 0) {
          cartToDb.change = 0;
          const pMindex = tillDataPaymentMethods.findIndex((pi) => pi.name.toLowerCase() === "cash");
          cartToDb.payments.push({ ...tillDataPaymentMethods[pMindex], amount: 0 });
        } else {
          cartToDb.change = change;
        }

        const cPi = cartToDb.payments.findIndex((pi) => pi.name.toLowerCase() === "cash");

        if (cPi >= 0) {
          const updatedCashAmt = parseFloat(cartToDb.payments[cPi].amount) - change;
          cartToDb.payments[cPi].amount = `${updatedCashAmt}`;
        } else {
          const updatedCashAmt = parseFloat(cartToDb.payments[0].amount) - change;
          cartToDb.payments[0].amount = `${updatedCashAmt}`;
        }
      } else {
        cartToDb.layAway = "Y";
      }

      if(posConfig.loyaltyApplicable === "Y") {
        if (cartToDb.customer.loyaltyLevel.cwrLoyaltyLevelId) {
          let totalPrice = 0;
          tillData.loyaltyApply.map((item, index) => {
            if (item.applicableFor === "All") {
              cartToDb.accumulationPoints = parseFloat(cartToDb.customer.loyaltyLevel.accumulationRate) * cartToDb.total;
            } else if (item.applicableFor === "CST") {
              cartToDb.items.map((cartItem, cartIndex) => {
                let filterData =
                  item.prodCategories.length > 0 &&
                  item.prodCategories.filter((categoryItem) => categoryItem.include === "Y" && categoryItem.mProductCategoryId === cartItem.mProductCategoryId);
                if (filterData.length > 0) {
                  totalPrice += cartToDb.items[cartIndex].salePrice;
                }
              });
            }
            cartToDb.accumulationPoints = parseFloat(cartToDb.customer.loyaltyLevel.accumulationRate) * totalPrice;
          });
        }
      }

      // cartToDb.sOrderID = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.orderTime = timeStamp();
      cartToDb.createdBy = tillData.tillAccess.csUserId;
      cartToDb.documentno = `${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}`;
      cartToDb.orderType = orderType.cwrSaletype.cwrSaletypeId;
      cartToDb.tillSessionId = tillSessionId;
      cartToDb.isSynced = 0;
      cartToDb.syncAttempts = 0;
      cartToDb.customerSearchKey = cartToDb.customer.code;

      // console.log("Order ->", cartToDb);

      db.orders
        .add(cartToDb)
        .then(() => {
          setPaymentModal(false);
          deleteCart();
          localStorage.setItem("documentSequence", newDocumentSequence);
          setDocumnetSequence(newDocumentSequence);
          message.success(`Order ${cartToDb.documentno} Created Successfully`);
          orderState.current = 0;
          setActiveOrderProcess(false);
          localStorage.removeItem("totalDiscountsCartRef");
          if (posConfig.showCustomerSearch === "Before Order") {
            setDisplayCustomerSearch(true);
          }
          if (cartToDb.hasOwnProperty("giftVoucherType")) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
                generateGiftVoucher(
                  giftVoucher: {
                    cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                    tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                    b2cCustomerId: "${cartToDb.customer.cwrCustomerId}"
                    giftVoucherType: "${cartToDb.giftVoucherType}"
                    voucherAmount: ${cartToDb.giftVoucherAmount}
                  }
                ) {
                  status
                  message
                  cwrGiftVoucherId
                  voucherAmount
                  voucherCode
                }
              }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${tokens.token_type} ${tokens.access_token}`,
              },
            })
              .then((generateGiftVoucher) => {
                const gGV = generateGiftVoucher.data.data.generateGiftVoucher;
                if (gGV.status === "200") {
                  cartToDb.voucherCode = gGV.voucherCode;
                  printReceiptData(cartToDb);
                } else {
                  throw new Error(gGV);
                }
              })
              .catch((error) => {
                console.error(error);
                cartToDb.voucherCode = null;
                printReceiptData(cartToDb);
              });
          } else {
            cartToDb.voucherCode = null;
            printReceiptData(cartToDb);
          }
        })
        .catch((error) => {
          console.error("Failed to place an order: ", error);
        });
    }
  };

  //// CART OPERATIONS END ////

  // OFFERS AND DISCOUNTS BLOCK START
  const [offerProductsList, setOfferProductList] = useState();
  const [displayOfferProductSelectiton, setDisplayOfferProductSelection] = useState(false);

  const selectOfferProduct = (product) => {
    const pro = product;
    /* const billCart = cart;
    billCart.totalQty = billCart.totalQty + 1;
    billCart.items.push(pro);
    setCart(billCart); */
    addProduct(pro, 1);
    setDisplayOfferProductSelection(false);
    message.success(`${pro.name} (Free) Added Successfully`);
    setPaymentModal(true);
  };
  // OFFERS AND DISCOUNTS BLOCK START

  //// PAYMENTS BLOCK START ////
  const [paymentModal, setPaymentModal] = useState(false);
  const [amount, setAmount] = useState("");

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [loader, setLoader] = useState(false);

  const onChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleAmount = (value) => {
    if (tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N") {
      return null;
    }
    if (amount === "" && value === "x") {
      setAmount("");
    } else if (value === "x") {
      setAmount(`${amount.toString().substring(0, amount.toString().length - 1)}`);
    } else {
      setAmount(`${amount}${value}`);
    }
  };

  const [paymentModalByCustomerState, setPaymentModalByCustomerState] = useState(false);

  const openPaymentModal = () => {
    if (posConfig.showCustomerSearch === "Before Payment") {
      console.log("Before Payment");
      setDisplayCustomerSearch(true);
      setPaymentModalByCustomerState(true);
    } else {
      console.log("openPaymentModalByCustomer");
      openPaymentModalByCustomer();
    }
  };

  const openPaymentModalByCustomer = () => {
    console.log("openPaymentModalByCustomer");
    localStorage.setItem("totalDiscountsCartRef", JSON.stringify(cart));
    setPaymentModalByCustomerState(false);
    if (cart.items.length > 0) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (timeValidator(pricingRule.startDate, pricingRule.endDate)) {
              if (pricingRule.timeSpecific === "Y") {
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime.substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                const weekDay = currentDay();
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processBillDiscounts(pricingRule);
                } else {
                  setPaymentModal(true);
                }
              } else {
                processBillDiscounts(pricingRule);
              }
            } else {
              setPaymentModal(true);
            }
          }
        } else {
          setPaymentModal(true);
        }
      });
    }
  };

  const processBillDiscounts = (pricingRule) => {
    if (pricingRule.type === "TD" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      const billCart = { ...cart };
      let discountValidator = true;

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === item.mProductCategoryId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBrands.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === item.productBrandId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === item.csBunitId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (saleTypeValidation && discountValidator) {
        if (billCart.total >= pricingRule.billAmount && billCart.total <= pricingRule.maxBillAmount) {
          let discountValue = 0;
          discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
          if (pricingRule.issueGiftVoucher === "N") {
            // const roundOffValue = Math.round(billCart.total - discountValue);
            // const totalRoundOff = billCart.total - roundOffValue;
            // billCart.mPricingruleId = pricingRule.mPricingrulesId;
            // billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;
            // billCart.roundOff = totalRoundOff;
            // billCart.total = roundOffValue;
            // billCart.discount += discountValue;

            const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
            let salePricesTotal = 0;
            for (let i = 0; i < cartItemsFiltered.length; i += 1) {
              salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
            }

            billCart.items.map((addedToCart, index) => {
              if (addedToCart.nextRule === "Y" || addedToCart.nextRule === undefined) {
                // const productAmount = billCart.manualDiscountApplied ? addedToCart.realPrice : addedToCart.salePrice;
                const discountAmt = parseFloat((addedToCart.realPrice / salePricesTotal) * discountValue) / addedToCart.weight;
                const sp = parseFloat(addedToCart.realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart.weight;
                const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
                addedToCart.salePrice = sp;
                addedToCart.taxAmount = tax;
                addedToCart.nettotal = mrp;
                addedToCart.discount = discountAmt * addedToCart.weight;
                addedToCart.discountName = pricingRule.printedName;
                addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
                billCart.items[index] = addedToCart;
              }
            });

            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;

            const addedToCart = billCart.items;

            let totalTax = 0;
            let totalPrice = 0;
            let totalItemsQty = 0;
            let totalDiscounts = 0;
            for (let i = 0; i < addedToCart.length; i += 1) {
              totalPrice += addedToCart[i].nettotal;
              totalItemsQty += addedToCart[i].weight;
              totalTax += addedToCart[i].taxAmount;
              totalDiscounts += addedToCart[i].discount;
              addedToCart[i].key = i;
            }

            const roundOffValue = Math.round(totalPrice);
            const totalRoundOff = totalPrice - roundOffValue;
            totalPrice = roundOffValue;

            setCart({
              ...cart,
              items: [...addedToCart],
              total: totalPrice,
              tax: totalTax,
              discount: totalDiscounts,
              totalQty: totalItemsQty,
              roundOff: totalRoundOff,
            });
          } else {
            billCart.giftVoucherType = pricingRule.giftVoucherType;
            billCart.giftVoucherAmount = discountValue;
            setCart({ ...billCart });
          }
          setPaymentModal(true);
          message.success(pricingRule.printedName);
        }
      }
    } else if (pricingRule.type === "TDF" && pricingRule.manualDiscount === "N") {
      const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
      let discountValidator = true;
      const billCart = { ...cart };

      if (pricingRule.mPricingCcategories.length > 0) {
        discountValidator = pricingRule.mPricingCcategories.some((pc) => pc.mPricingCcategoryId === cart.customer.sCustomer.customerCategory.sCustomerCateforyId);
      }

      if (pricingRule.mPricingPcategories.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mPricingPcategoryId === item.mProductCategoryId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBrands.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mPricingBrandId === item.productBrandId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (pricingRule.mPricingBUnits.length > 0) {
        let conditionValidatorCount = 0;
        billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === item.csBunitId);
          if (stateValidation) {
            conditionValidatorCount += 1;
          }
          return null;
        });
        if (billCart.items.length !== conditionValidatorCount) {
          discountValidator = false;
        }
      }

      if (saleTypeValidation && discountValidator) {
        if (billCart.total >= pricingRule.billAmount && billCart.total <= pricingRule.maxBillAmount) {
          if (pricingRule.mPricingXProducts.length > 0) {
            if (pricingRule.mPricingXProducts.length === 1) {
              db.products
                .where("mProductId")
                .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
                .toArray()
                .then((product) => {
                  if (product.length > 0) {
                    const obj = { ...product[0] };
                    let batchno = null;
                    let mBatchId = null;
                    let upc = null;
                    //console.log(obj);
                    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                      const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                      batchno = obj.mBatch[bI].batchno;
                      mBatchId = obj.mBatch[bI].mBatchId;
                      upc = obj.mBatch[bI].upc;
                    }
                    if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                      obj.cTaxId = obj.contraTaxId;
                      obj.taxRate = obj.contraRate;
                    }
                    const productDefined = {
                      batchno: batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isPromoApplicable: false,
                      isReturn: false,
                      mBatchId: mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: 0,
                      returnQty: null,
                      salePrice: 0,
                      stock: obj.onhandQty,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      upc: upc,
                      value: obj.value,
                      weight: 1,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                      csBunitId: obj.csBunitId,
                      mProductCategoryId: obj.mProductCategoryId,
                      productManufacturerId: obj.productManufacturerId,
                      productBrandId: obj.productBrandId,
                      batchedProduct: obj.batchedProduct,
                      batchedForSale: obj.batchedForSale,
                      batchedForStock: obj.batchedForStock,
                      multiPrice: obj.multiPrice,
                    };
                    addProduct(productDefined, 1);
                    message.success(pricingRule.printedName);
                    setPaymentModal(true);
                  }
                });
            } else {
              const offerProductsStack = [];
              for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
                db.products
                  .where("mProductId")
                  .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                  .toArray()
                  .then((product) => {
                    //console.log(product);
                    if (product.length > 0) {
                      const obj = { ...product[0] };
                      let batchno = null;
                      let mBatchId = null;
                      let upc = null;
                      if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                        const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                        if (bI >= 0) {
                          batchno = obj.mBatch[bI].batchno;
                          mBatchId = obj.mBatch[bI].mBatchId;
                          upc = obj.mBatch[bI].upc;
                        }
                      }
                      if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                        obj.cTaxId = obj.contraTaxId;
                        obj.taxRate = obj.contraRate;
                      }
                      const productDefined = {
                        batchno: batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isReturn: false,
                        isPromoApplicable: false,
                        mBatchId: mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: 0,
                        returnQty: null,
                        salePrice: 0,
                        stock: obj.onhandQty,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        upc: upc,
                        value: obj.value,
                        weight: 1,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                        batchedProduct: obj.batchedProduct,
                        batchedForSale: obj.batchedForSale,
                        batchedForStock: obj.batchedForStock,
                        multiPrice: obj.multiPrice,
                      };
                      offerProductsStack.push(productDefined);
                    }
                  });
              }
              setTimeout(() => {
                setOfferProductList([...offerProductsStack]);
                setCart((currentBillCart) => ({
                  ...currentBillCart,
                  mPricingrulesId: pricingRule.mPricingrulesId,
                }));
                setDisplayOfferProductSelection(true);
              }, 1000);
            }
          }
        }
      }
    } else {
      setPaymentModal(true);
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setSelectedPaymentMethod("");
    const billCart = JSON.parse(localStorage.getItem("totalDiscountsCartRef"));
    localStorage.removeItem("totalDiscountsCartRef");
    setCart({ ...billCart });
  };

  const [loyaltyPaymentOtp, setLoyaltyPaymentOtp] = useState();
  const [loyalityOtpModalVisible, setLoyalityOtpModalVisible] = useState(false);
  const [loyalityOtpData, setLoyalityOtpData] = useState();

  const processOtpInput = () => {
    const { otp, paymentMethod, value, redeemPoints } = loyalityOtpData;
    setLoyalityOtpModalVisible(false);
    if (otp === loyaltyPaymentOtp) {
      message.success("Loyality Points Reedemed !");
      paymentMethod.redemptionPoints = parseInt(redeemPoints);
      processPayment(paymentMethod, value);
    } else {
      message.warning("Invalid OTP");
    }
    setLoyalityOtpData();
    setLoyaltyPaymentOtp();
  };

  const processInnovitiEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    if (amountAdded >= 1) {
      const requestCode = innovitiPaymentCodes[currentPaymentMethod.name];
      const trxId = uuidv4().replace(/-/g, "").toUpperCase();
      const cashierId = tillData.tillAccess.csUserId;
      const customerMobileNo = cart.customer.mobileNo;
      setLoader(true);
      setPaymentModal(false);
      console.info(`Innoviti Request URL: ${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`);
      Axios.get(`${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`)
        .then((response) => {
          const result = response.data;
          const { ResponseCode, ResponseMessage } = result;
          if (ResponseCode === "00") {
            setLoader(false);
            setPaymentModal(true);
            message.success("Payment Success");
            processPayment(paymentMethod, value);
          } else {
            setLoader(false);
            setPaymentModal(true);
            message.error(`Payment Failed: ${ResponseMessage}`);
          }
        })
        .catch((error) => {
          console.error("Payment Failed:", error);
          setLoader(false);
          setPaymentModal(true);
          message.error("Payment Failed: Transaction timeout / Check EDC Connection");
        });
    } else {
      console.warn("Minimum amount not satisfied");
    }
  };

  const cancelPaytmVerifyPaymentRequest = (options) => {
    options.data.type = 6;
    Axios(options);
  };

  const paytmVerifyEDCPaymentStatus = (options) => {
    return new Promise(async function (verify) {
      Modal.confirm({
        title: "Payment request initiated",
        content: "Please verify",
        okText: "Verify",
        onOk: () => {
          verify("retry");
        },
        onCancel: () => {
          cancelPaytmVerifyPaymentRequest(options);
          verify("cancel");
        },
      });
    });
  };

  const chekPaytmEDCPaymentStatus = (options) => {
    return new Promise(async function (process, reject) {
      let retries = true;
      while (retries) {
        try {
          const response = await Axios(options);
          const result = response.data;
          // const result = { "result": 0, "resultCode": "101", "resultMessage": "Transaction already present", "transactionID": null, "authorizationID": null, "request": null, "properties": null };
          if (result.result?.toString() === "200") {
            retries = false;
            process(true);
          } else if (result.result?.toString() === "202" || result.result?.toString() === "203") {
            message.info(result.resultMessage);
            const retryStatus = await paytmVerifyEDCPaymentStatus(options);
            if (retryStatus === "retry") {
              retries = true;
            } else {
              message.error("Payment canceled");
              retries = false;
              process(false);
            }
          } else {
            message.error(result.resultMessage);
            retries = false;
            process(false);
          }
        } catch (err) {
          retries = false;
          reject(err);
        }
      }
    });
  };

  const processPaytmEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();
    setLoader(true);
    setPaymentModal(false);
    const requestData = {
      url: `${tillData.tillAccess.cwrTill.hardwareController.imageUrl}payment`,
      method: "POST",
      data: {
        type: 0,
        transactionID: trxId,
        terminalID: "111",
        currency: "INR",
        amount: amountAdded,
        properties: {},
        paymentProvider: "Paytm",
      },
      headers: {
        "Content-Type": "Application/json",
      },
    };

    chekPaytmEDCPaymentStatus(requestData)
      .then((response) => {
        if (response) {
          setLoader(false);
          setPaymentModal(true);
          message.success("Payment Success");
          processPayment(paymentMethod, value);
        } else {
          setLoader(false);
          setPaymentModal(true);
        }
      })
      .catch((error) => {
        console.error("Payment Failed:", error);
        setLoader(false);
        setPaymentModal(true);
        message.error("Payment Failed: Transaction timeout / Check EDC Connection");
      });
  };

  const [paymentModalLoyalityMessages, setPaymentModalLoyalityMessages] = useState({ inputMessage: "Enter Amount", buttonText: "Add Payment" });
  useEffect(() => {
    if (selectedPaymentMethod.isloyalty) {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Points to Reedem",
        buttonText: "Reedem",
      });
    } else if (selectedPaymentMethod.isGiftCard) {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Coupon Code",
        buttonText: "Reedem",
      });
    } else {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Amount",
        buttonText: "Add Payment",
      });
    }
  }, [selectedPaymentMethod]);

  const requestPayment = (paymentMethod, value, grant) => {
    if (grant !== undefined) {
      processPayment(paymentMethod, value);
    } else {
      const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
      if (paymentMethod !== undefined && paymentMethod !== "" && validation.test(parseFloat(value))) {
        const currentPaymentMethod = paymentMethod;
        const amountAdded = parseFloat(value).toFixed(2);
        if (currentPaymentMethod.integratedPayment) {
          if (currentPaymentMethod.paymentProvider === "INV") {
            processInnovitiEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else if (currentPaymentMethod.paymentProvider === "PTM") {
            processPaytmEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else {
            message.error("Invalid payment provider configuration");
          }
        } else if (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === "PTM") {
            processQrPaytmPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
        } else if (currentPaymentMethod.iscredit) {
          if (cart.customer.iscredit) {
            setLoader(true);
            setPaymentModal(false);
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `query{
                verifyCredit(customerId:"${cart.customer.cwrCustomerId}"){
                    iscredit
                    creditLimit
                }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${tokens.token_type} ${tokens.access_token}`,
              },
            })
              .then((creditResponse) => {
                const { iscredit, creditLimit } = creditResponse.data.data.verifyCredit;
                if (iscredit) {
                  if (amountAdded > 0 && amountAdded <= creditLimit) {
                    message.success("Credit Payment Granted");
                    processPayment(paymentMethod, value);
                  } else {
                    message.warning("Credit limit exceeded!");
                  }
                } else {
                  message.warning("Customer not eligible for credit payment !");
                }
              })
              .catch((err) => {
                console.error(err);
                message.error("Payment Failed !");
              })
              .finally(() => {
                setLoader(false);
                setPaymentModal(true);
              });
          } else {
            message.warning("Credit payment not available for customer !");
          }
        } else if (currentPaymentMethod.isloyalty) {
          setLoader(true);
          setPaymentModal(false);
          if (cart.customer.loyaltyLevel.cwrLoyaltyLevelId) {
            const redeemptionRate = cart.customer.loyaltyLevel.redemptionRate;
            const redeemPoints = parseFloat(value);
            const amountForRedeem = parseFloat(redeemptionRate) * redeemPoints;
            if (redeemPoints <= parseFloat(cart.customer.retlLoyaltyBalance)) {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation{
                    verifyLoyalty(customerId:"${cart.customer.cwrCustomerId}", redeemPoint:${redeemPoints}){
                      status
                      message
                      otp
                    }
                  }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              })
                .then((loyalityResponse) => {
                  // const loyalityResponse = {data:{data:{verifyLoyalty:{ status: "Success", otp: "12345", message: "OTP Generated !"}}}};
                  const { status, otp } = loyalityResponse.data.data.verifyLoyalty;
                  const responseMessage = loyalityResponse.data.data.verifyLoyalty.message;
                  if (status === "Success") {
                    message.success(responseMessage);
                    setLoyalityOtpData({
                      otp: otp,
                      paymentMethod: paymentMethod,
                      value: amountForRedeem,
                      redeemPoints: redeemPoints,
                    });
                    setLoyalityOtpModalVisible(true);
                  } else {
                    message.warning(responseMessage);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  message.error("Payment Failed !");
                })
                .finally(() => {
                  setLoader(false);
                  setPaymentModal(true);
                });
            } else {
              message.warning("Insuffient loyality points !");
              setLoader(false);
              setPaymentModal(true);
            }
          } else {
            message.warning("Loyalty not found for selected customer !");
            setLoader(false);
            setPaymentModal(true);
          }
        } else if (currentPaymentMethod.isGiftCard) {
          const couponCodeFromAmount = parseFloat(value);
          setLoader(true);
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                redeemVoucher(couponCode:${couponCodeFromAmount}, bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
                  cwrGiftVoucherId
                  cSBunitID
                  pinRequired
                  pinNo
                  voucherAmount
                  amountAvailable
                  redeemed
                  tillId
                  b2cCustomerId
                }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          })
            .then((redeemVoucherResponse) => {
              const redeemVoucherResponseData = redeemVoucherResponse.data.data.redeemVoucher;
              if (redeemVoucherResponseData !== null) {
                let amountRedeemedValue = parseFloat(redeemVoucherResponseData[0].amountAvailable);
                if (amountRedeemedValue > parseFloat(cart.total)) {
                  amountRedeemedValue = parseFloat(cart.total);
                }
                message.success("Coupon Reedemed !");
                processPayment(paymentMethod, amountRedeemedValue);
              } else {
                message.warn("Invalid Coupon !");
              }
            })
            .catch((error) => {
              console.error(error);
              message.warn("Unable to access info right now !");
            })
            .finally(() => {
              setLoader(false);
            });
        } else {
          processPayment(paymentMethod, value);
        }
      } else {
        message.warn("Invalid Payment !");
      }
    }
  };

  const processPayment = (paymentMethod, value) => {
    const paymentAddons = cart.payments;
    const amountAdded = parseFloat(value);
    const currentPaymentMethod = paymentMethod;
    let shouldRemoveRedeemPoints = false;

    if (paymentMethod.isloyalty && amountAdded < 0) {
      shouldRemoveRedeemPoints = true;
    }

    const index = paymentAddons.findIndex((p) => p.finPaymentmethodId === currentPaymentMethod.finPaymentmethodId);
    if (index >= 0) {
      const newAmount = parseFloat(paymentAddons[index].amount) + parseFloat(amountAdded);
      paymentAddons[index].amount = parseFloat(newAmount).toFixed(2);
      if (parseFloat(paymentAddons[index].amount) === 0) {
        paymentAddons.splice(index, 1);
      }
    } else {
      if (amountAdded !== 0) {
        currentPaymentMethod.amount = parseFloat(amountAdded).toFixed(2);
        paymentAddons.push(currentPaymentMethod);
      }
    }

    let totalAmountAdded = 0;
    const paymentMethodsCount = paymentAddons.length;
    for (let i = 0; i < paymentMethodsCount; i += 1) {
      totalAmountAdded += parseFloat(paymentAddons[i].amount);
    }

    if (paymentMethod.redemptionPoints) {
      cart.redemptionPoints = paymentMethod.redemptionPoints;
    }

    const redemptionPts = shouldRemoveRedeemPoints ? 0 : cart.redemptionPoints;

    let creditAmountValue = cart.creditAmount;
    if (paymentMethod.iscredit && amountAdded > 0) {
      creditAmountValue = amountAdded;
    }

    if (paymentMethod.iscredit && amountAdded < 0) {
      creditAmountValue = 0;
    }

    setCart({
      ...cart,
      paid: totalAmountAdded,
      payments: [...paymentAddons],
      redemptionPoints: redemptionPts,
      creditAmount: creditAmountValue,
    });
    setSelectedPaymentMethod("");
    setAmount("");
  };
  //// PAYMENTS BLOCK END ////

  const eBillConfig = useRef({});
  useEffect(() => {
    Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          getPOSConfig(application: "E-Bill") {
            cwrPosConfigId
            application
            configJson
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((resp) => {
      const ebConfig = JSON.parse(resp.data.data.getPOSConfig[0].configJson);
      eBillConfig.current = ebConfig;
    });
  }, []);

  const sendWebHookData = async (pendingOrders) => {
    try {
      // const orders = await db.orders.where("isSynced").equals(0).toArray();
      const orders = [...pendingOrders];
      for (const order of orders) {
        let shortId = "";
        const { eBillWebHookURL } = eBillConfig.current;
        try {
          const { data } = await Axios.get(`${eBillWebHookURL}/ebill/uniqueid`);
          shortId = data;
        } catch (error) {
          console.error(error);
          throw new Error("Failed to Fetch ID");
        }
        const orderItems = [];
        order.items.forEach((orderItem, i) => {
          orderItems.push({
            line_no: i + 1,
            sku: orderItem.value,
            name: orderItem.name,
            description: null,
            hsn_code: null,
            selling_price: orderItem.salePrice,
            quantity: orderItem.weight,
            amount: orderItem.nettotal,
            list_price: orderItem.realPrice,
            uom: orderItem.uom_name,
            attributes: {
              mc: "",
              symc: "",
            },
            taxes: [
              {
                tax_name: "GST",
                percentage: orderItem.taxRate,
                amount: orderItem.taxAmount,
                taxableAmount: orderItem.nettotal,
              },
            ],
            brand: null,
            categoryId: orderItem.mProductCategoryId,
            categoryName: null,
          });
        });

        const payments = [];
        order.payments.forEach((payment, i) => {
          payments.push({
            payment_method: payment.name,
            amount: payment.amount,
          });
        });

        const orderData = {
          eBillCommType: eBillConfig.current.eBillCommType,
          created: order.orderTime,
          updated: order.orderTime,
          short_id: shortId,
          customer: {
            customer_id: order.customer.cwrCustomerId,
            first_name: order.customer.name,
            last_name: "",
            mobile: order.customer.mobileNo,
          },
          order_type: order.orderType,
          order_id: order.sOrderID,
          bill_no: order.documentno,
          bill_status: "PAID",
          order_date: order.orderTime,
          items: orderItems,
          item_count: order.items.length,
          total_qty: order.totalQty,
          net_total: order.total,
          gross_total: order.total,
          payment: payments,
          taxes: [
            {
              tax_name: "GST",
              amount: order.tax,
              taxableAmount: order.total,
            },
          ],
        };

        try {
          await Axios.post(`${eBillWebHookURL}/ebill/webhook`, orderData);
          //console.log(`${eBillWebHookURL}/ebill/webhook`, orderData);
        } catch (error) {
          console.error(error);
          throw new Error("Failed to POST Webhook");
        }
      }
    } catch (e) {
      console.error("Webhook Error", e);
    }
  };

  // ORDER SYNC BLOCK START
  const syncOrders = (syncTrigger) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      db.logInformation.toArray().then((fetched) => {
        if(fetched.length > 0) {
        const posLogArray = [];
        fetched.forEach((item) => {
          let value = { ...item };
          db.logInformation.where("id").equals(value.id).delete();
          delete value.id;
          posLogArray.push(`{
            type: "${value.type}", 
            action: "LOG", 
            description: "${value.description}", 
            date: "${value.date}",
            time: "${value.time}",    
            orderNo: "${value.orderNo}",
            remarks: "${value.remarks}"
        }`);
        });
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
      upsertPOSLog(order: {
          tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
          userId: "${tillData.tillAccess.csUserId}" 
          bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
          lines: [${posLogArray}]
        }) {
        status   
        message
      }
    }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        });
      }
      });

      const csCurrencyId = localStorage.getItem("csCurrencyId");
      db.orders
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            if (eBillConfig.current.eBill === "Y") {
              sendWebHookData(orders);
            }
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const orderLines = [];
              for (let j = 0; j < orders[i].items.length; j += 1) {
                let unitPrice = orders[i].items[j].nettotal / orders[i].items[j].weight;
                unitPrice = parseFloat(unitPrice).toFixed(2);
                orderLines.push(`{
              sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              sOrderId: "${orders[i].sOrderID}",
              line: ${(j + 1) * 10},
              description: "",
              mProductId: "${orders[i].items[j].productId}",
              csUomId: "${orders[i].items[j].uom}",
              csTaxId: "${orders[i].items[j].tax}",
              qty: ${orders[i].items[j].weight},
              unitprice: ${unitPrice},
              netlist: ${orders[i].items[j].salePrice}
              discount: ${orders[i].items[j].discount},
              returnline: ${orders[i].items[j].isReturn},
              returnQty: 0,
              mBatchId: ${orders[i].items[j].mBatchId !== null ? `"${orders[i].items[j].mBatchId}"` : null},
					    mPricingruleId: ${orders[i].items[j].mPricingruleId !== null && orders[i].items[j].mPricingruleId !== undefined ? `"${orders[i].items[j].mPricingruleId}"` : null},
              batchedForSale:"${orders[i].items[j].batchedForSale}",
              batchedForStock:"${orders[i].items[j].batchedForStock}",
              batchedProduct:"${orders[i].items[j].batchedProduct}",
              salesRepId: ${orders[i].items[j].salesRepId !== null && orders[i].items[j].salesRepId !== undefined ? `"${orders[i].items[j].salesRepId}"` : null},
              multiPrice: "${orders[i].items[j].multiPrice}",
              discountTypeId: null,
              discountAmount: null,
            }`);
              }
              const paymentsList = [];
              let amtMax = -100000000000000000000;
              let maxFinPaymentMethod;
              for (let k = 0; k < orders[i].payments.length; k += 1) {
                if (amtMax < parseFloat(orders[i].payments[k].amount)) {
                  amtMax = parseFloat(orders[i].payments[k].amount);
                  maxFinPaymentMethod = orders[i].payments[k].finPaymentmethodId;
                }
                paymentsList.push(`{
                  finPaymentmethodID: "${orders[i].payments[k].finPaymentmethodId}",
                  amount: "${orders[i].payments[k].amount}"
                }`);
              }
              const paramsInput = {
                query: `mutation{
              posOrderProcessor(posOrder:{
              sOrderID: "${orders[i].sOrderID}",
              cSClientID: "${tillData.tillAccess.csClientId}",
              cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
              sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
              sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
              documentno: "${orders[i].documentno}",
              dateordered: "${orders[i].orderTime}",
              datepromised: "${orders[i].orderTime}",
              csPaymenttermID: null,
              finPaymentmethodId: ${orders[i].layAway === "N" ? `"${maxFinPaymentMethod}"` : null},
              csCurrencyId: "${csCurrencyId}",
              mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
              cwrLongitude: "",
              cwrLatitude: "",
              csUserId: "${tillData.tillAccess.csUserId}",
              cwrB2cCustomerId: "${orders[i].customer.cwrCustomerId}",
              orderreference: "",
              cwrPayref: "",
              cwrPayremarks: "",
              description: "",
              cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
              redemption: ${orders[i].redemptionPoints},
              accumulation: ${orders[i].accumulationPoints},
              roundoff: 0,
              cwrProductQty: ${orders[i].items.length},
              cwrProductCount: ${orders[i].totalQty},
              ofdStatus: "Delivered",
              ofdIspaid: "Y",
              mPricingruleId: ${orders[i].mPricingruleId !== null && orders[i].mPricingruleId !== undefined ? `"${orders[i].mPricingruleId}"` : null},
			        cwrSaletypeId: "${orders[i].orderType}",
              salesRepId:${orders[i].salesRepId !== null && orders[i].salesRepId !== undefined ? `"${orders[i].salesRepId}"` : null},
              discAmount: ${orders[i].discount},
              creditAmount: ${orders[i].creditAmount},
              pricingCoupon:{
                mPricingCouponId:${orders[i].mPricingCouponId ? `"${orders[i].mPricingCouponId}"` : null},
                redemptionCount:${orders[i].couponRedemptionCount ? parseInt(orders[i].couponRedemptionCount) : null},
              }
              isReturn: ${orders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
              sOrderReturnId: null,
              layAway: "${orders[i].layAway}",
              payments: [${paymentsList}],
              line: [${orderLines}]
              })
            {
              documentno 
              status
              message
             }
            }
            `,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              })
                .then((response) => {
                  const result = response.data.data.posOrderProcessor;
                  const { status } = result;
                  if (status === "200") {
                    console.info(`Order ${orders[i].documentno} synced to Server`);
                    db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 1 });
                  } else {
                    console.error("Failed Order Sync ====> ", response);
                    const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    if (parseInt(orders[i].syncAttempts) < 100) {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    } else {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    }
                  }
                })
                .catch((error) => {
                  const err = JSON.parse(JSON.stringify(error));
                  const { message } = err;
                  if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                    const refToken = tokens.refresh_token;
                    const requestBody = {
                      grant_type: "refresh_token",
                      refresh_token: refToken,
                    };
                    const config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                      },
                    };
                    Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                      setAuthTokens(newToken.data);
                    });
                  } else {
                    const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    if (parseInt(orders[i].syncAttempts) < 100) {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    } else {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    }
                  }
                });
            }
          }
        });
    }
    if (syncTrigger === "orderHistory") {
      showOrderHistory();
    }
  };

  useEffect(() => {
    const syncOrdersInterval = setInterval(() => syncOrders(), 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ORDER SYNC BLOCK END

  const handleCloseModal = () => {
    history.push("/dashboard");
  };

  const confirmDiscardCart = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        className: "parkedItemsClass",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Items in cart will be lost.
            <br />
            Please save in order to retrive later
          </div>
        ),
        okText: "Save",
        cancelText: "No",
        onOk() {
          parkBill();
          setTimeout(() => {
            history.push("/dashboard");
          }, 700);
        },
        onCancel: () => {
          handleCloseModal();
        },
      });
    } else {
      history.push("/dashboard");
    }
  };

  // useEffect(() => {
  //   if (parkedList.length > 0) {
  //     selectParkedBill(parkedList[0]);
  //   }
  // }, []);

  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponInput, setCouponInput] = useState("");

  const closeCouponModal = () => {
    setCouponModalVisible(false);
    setCouponInput("");
  };

  const checkCoupon = async () => {
    setCouponModalVisible(false);
    const verifyCouponResponse = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          verifyCoupons(couponcode: "${couponInput}") {
            mPricingCouponId
            csClientId
            csBunitId
            created
            createdBy
            updated
            updatedBy
            line
            mPricingRulesId
            couponcode
            status
            redemptionCount
            usedDate
            upc
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });
    const { verifyCoupons } = verifyCouponResponse.data.data;
    if (verifyCoupons.length > 0) {
      if (verifyCoupons[0].status === "A") {
        const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(verifyCoupons[0].mPricingRulesId).toArray();

        if (matchingPricingRules.length > 0) {
          const pricingRule = matchingPricingRules[0];
          if (pricingRule.type === "TD" && pricingRule.iscoupon === "Y") {
            const billCart = { ...cart };
            let discountValue = 0;
            discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
            /* const roundOffValue = Math.round(billCart.total - discountValue);
            const totalRoundOff = billCart.total - roundOffValue;
            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;
            billCart.roundOff = totalRoundOff;
            billCart.total = roundOffValue;
            billCart.discount += discountValue;
            billCart.mPricingCouponId = verifyCoupons[0].mPricingCouponId;
            billCart.couponRedemptionCount = parseFloat(verifyCoupons[0].redemptionCount) + 1;
            setCart({ ...billCart }); */

            const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
            let salePricesTotal = 0;
            for (let i = 0; i < cartItemsFiltered.length; i += 1) {
              salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
            }

            billCart.items.map((addedToCart, index) => {
              if (addedToCart.nextRule === "Y" || addedToCart.nextRule === undefined) {
                // const productAmount = billCart.manualDiscountApplied ? addedToCart.realPrice : addedToCart.salePrice;
                const discountAmt = parseFloat((addedToCart.realPrice / salePricesTotal) * discountValue) / addedToCart.weight;
                const sp = parseFloat(addedToCart.realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart.weight;
                const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
                addedToCart.salePrice = sp;
                addedToCart.taxAmount = tax;
                addedToCart.nettotal = mrp;
                addedToCart.discount = discountAmt * addedToCart.weight;
                addedToCart.discountName = pricingRule.printedName;
                addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
                billCart.items[index] = addedToCart;
              }
            });

            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;

            const addedToCart = billCart.items;

            let totalTax = 0;
            let totalPrice = 0;
            let totalItemsQty = 0;
            let totalDiscounts = 0;
            for (let i = 0; i < addedToCart.length; i += 1) {
              totalPrice += addedToCart[i].nettotal;
              totalItemsQty += addedToCart[i].weight;
              totalTax += addedToCart[i].taxAmount;
              totalDiscounts += addedToCart[i].discount;
              addedToCart[i].key = i;
            }

            const roundOffValue = Math.round(totalPrice);
            const totalRoundOff = totalPrice - roundOffValue;
            totalPrice = roundOffValue;

            setCart({
              ...cart,
              items: [...addedToCart],
              total: totalPrice,
              tax: totalTax,
              discount: totalDiscounts,
              totalQty: totalItemsQty,
              roundOff: totalRoundOff,
            });
            setPaymentModal(true);
            message.success(`Coupon Applied: ${pricingRule.printedName}`);
          }

          if (pricingRule.type === "TDF" && pricingRule.iscoupon === "Y") {
            if (pricingRule.mPricingXProducts.length > 0) {
              if (pricingRule.mPricingXProducts.length === 1) {
                db.products
                  .where("mProductId")
                  .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
                  .toArray()
                  .then((product) => {
                    if (product.length > 0) {
                      const obj = { ...product[0] };
                      let batchno = null;
                      let mBatchId = null;
                      let upc = null;
                      if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                        const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                        batchno = obj.mBatch[bI].batchno;
                        mBatchId = obj.mBatch[bI].mBatchId;
                        upc = obj.mBatch[bI].upc;
                      }
                      if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                        obj.cTaxId = obj.contraTaxId;
                        obj.taxRate = obj.contraRate;
                      }
                      const productDefined = {
                        batchno: batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isPromoApplicable: false,
                        isReturn: false,
                        mBatchId: mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: 0,
                        returnQty: null,
                        salePrice: 0,
                        stock: obj.onhandQty,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        upc: upc,
                        value: obj.value,
                        weight: 1,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                        batchedProduct: obj.batchedProduct,
                        batchedForSale: obj.batchedForSale,
                        batchedForStock: obj.batchedForStock,
                        multiPrice: obj.multiPrice,
                      };
                      addProduct(productDefined, 1);
                      message.success(pricingRule.printedName);
                      setPaymentModal(true);
                    }
                  });
              } else {
                const offerProductsStack = [];
                for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
                  db.products
                    .where("mProductId")
                    .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                    .toArray()
                    .then((product) => {
                      if (product.length > 0) {
                        const obj = { ...product[0] };
                        let batchno = null;
                        let mBatchId = null;
                        let upc = null;
                        if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                          const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                          if (bI >= 0) {
                            batchno = obj.mBatch[bI].batchno;
                            mBatchId = obj.mBatch[bI].mBatchId;
                            upc = obj.mBatch[bI].upc;
                          }
                        }
                        if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                          obj.cTaxId = obj.contraTaxId;
                          obj.taxRate = obj.contraRate;
                        }
                        const productDefined = {
                          batchno: batchno,
                          description: obj.description,
                          discount: 0,
                          discountName: "",
                          imageurl: obj.imageurl,
                          isDecimal: obj.isDecimal,
                          isManualQty: obj.isManualQty,
                          isReturn: false,
                          isPromoApplicable: false,
                          mBatchId: mBatchId,
                          mPricingruleId: null,
                          name: obj.name,
                          nettotal: 0,
                          primaryOrderLine: null,
                          productId: obj.mProductId,
                          realPrice: 0,
                          returnQty: null,
                          salePrice: 0,
                          stock: obj.onhandQty,
                          tax: obj.cTaxId,
                          taxAmount: 0,
                          taxRate: obj.taxRate,
                          uom: obj.csUomId,
                          uom_name: obj.uomName,
                          upc: upc,
                          value: obj.value,
                          weight: 1,
                          shortDescription: obj.shortDescription,
                          hsncode: obj.hsncode,
                          csBunitId: obj.csBunitId,
                          mProductCategoryId: obj.mProductCategoryId,
                          productManufacturerId: obj.productManufacturerId,
                          productBrandId: obj.productBrandId,
                          batchedProduct: obj.batchedProduct,
                          batchedForSale: obj.batchedForSale,
                          batchedForStock: obj.batchedForStock,
                          multiPrice: obj.multiPrice,
                        };
                        offerProductsStack.push(productDefined);
                      }
                    });
                }
                setTimeout(() => {
                  setOfferProductList([...offerProductsStack]);
                  setCart({
                    ...cart,
                    mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                    couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
                    mPricingrulesId: pricingRule.mPricingrulesId,
                  });
                  setDisplayOfferProductSelection(true);
                }, 1000);
              }
            }
          }

          if (pricingRule.type === "FD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });

            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = pricingRule.amountDiscount;
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;

              setCart({
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              });
            }
          }

          if (pricingRule.type === "PD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });
            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart[index].realPrice);
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;

              setCart({
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              });
            }
          }
        } else {
          message.warning("No offer found for coupon");
        }
      } else {
        message.warning("Coupon Expired");
      }
    } else {
      message.warning("Invalid Coupon");
    }
  };

  // Side Menu Drawer Starts //

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  // Side Menu Drawer Ends //

  // OMS Orders Modal Starts //

  const omsOrderDetailsList = JSON.parse(localStorage.getItem("omsOrderDetails"));
  const initialOmsOrderList = omsOrderDetailsList ? omsOrderDetailsList : [];
  const [omsOrdersList, setOmsOrderList] = useState(initialOmsOrderList);

  const omsOrderStatus = [
    {
      title: "New",
      imgSrc: NewWhite,
      selectedImgSrc: New,
      statusValue: "NE",
    },
    {
      title: "Preparing",
      imgSrc: PreparingWhite,
      selectedImgSrc: Preparing,
      statusValue: "UP",
    },
    {
      title: "Ready",
      imgSrc: ReadyWhite,
      selectedImgSrc: Ready,
      statusValue: "PK",
    },
    {
      title: "Today's Orders",
      imgSrc: CompletedWhite,
      selectedImgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOMSOrderItemsModal, setDisplayOMSOrderItemsModal] = useState(false);
  const [selectedOMSOrderStatus, setSelectedOMSOrderStatus] = useState(omsOrderStatus[0]);

  const [order, setOrder] = useState("");
  const [searchOrders, setSearchOrders] = useState(omsOrdersList);

  const [selectedOrder, setSelectedOrder] = useState({});

  const handleOmsOrders = () => {
    setSideMenuDrawervisible(false);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salePrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(omsOrdersList, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              omsOrdersList.push(orderObj);
            } else {
              omsOrdersList[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
        getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
        cWCOrderId
        orderNo
        dateCreated
        customerId
        customerName
        mobileNo
        total
        noOfItems
        totalQty
        lineItems{
            productId
            name
            productCode
            quantity
            price
            subTotal
            addOnProducts{
                id
                name
                price
            }
        }
    }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { getNewOmsOrders } = response.data.data;
      getNewOmsOrders?.map((item, index) => {
        let obj = { ...item };
        obj.status = "NE";
        obj.noOfItems = item.lineItems.length;
        obj.posOrders = "N";
        let time = new Date(item.dateCreated);
        let newTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        obj.dateCreated = newTime;
        let findIndex = _.findIndex(omsOrdersList, ["cWCOrderId", item.cWCOrderId]);
        if (findIndex === -1) {
          omsOrdersList.push(obj);
        } else {
          omsOrdersList[findIndex] = obj;
          let prodCode = _.map(omsOrdersList?.[findIndex]?.lineItems || [], "productCode");
          db.products
            .where("value")
            .startsWithAnyOfIgnoreCase(prodCode)
            .toArray()
            .then((productsFetched) => {
              if (productsFetched?.length > 0) {
                let newLineItems = [...omsOrdersList?.[findIndex]?.lineItems].map((itemLine) => {
                  let itemImageIndex = _.findIndex(productsFetched, (item) => item.value === itemLine.productCode);
                  return itemImageIndex >= 0
                    ? {
                        ...itemLine,
                        img: productsFetched[itemImageIndex]?.imageurl,
                      }
                    : { ...itemLine };
                });
                omsOrdersList[findIndex].lineItems = [...newLineItems];
              }
            });
        }
        return null;
      });
      localStorage.setItem("omsOrderDetails", JSON.stringify([...omsOrdersList]));
      setOmsOrderList([...omsOrdersList]);
    });
    setDisplayOMSOrderItemsModal(true);
  };

  const handleOMSOrderStatusSelection = (record) => {
    setSelectedOMSOrderStatus(record);
  };

  const setOMSStatus = (status) => {
    let newOMSStatus = "";
    switch (status) {
      case "NE":
        newOMSStatus = "UP";
        break;
      case "UP":
        newOMSStatus = "PK";
        break;
      case "PK":
        newOMSStatus = "DE";
        break;
      default:
        newOMSStatus = "NE";
    }
    return newOMSStatus;
  };

  const nextOMSOrderStatus = () => {
    let newOMSOrderStatus = [...omsOrderStatus];
    let statusIndex = _.findIndex(newOMSOrderStatus, (item) => item.title === selectedOMSOrderStatus.title);
    if (statusIndex <= newOMSOrderStatus.length - 1) setSelectedOMSOrderStatus(newOMSOrderStatus[statusIndex + 1]);
  };

  const handleOMSStatusButton = (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "${setOMSStatus(record.status)}"
        })
        {
            status
            message
        }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.updateOMSOrderStatus.status === "200") {
        let newOMSOrderDetails = [...omsOrdersList];
        let recordIndex = _.findIndex(newOMSOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOMSOrderDetails[recordIndex].status = setOMSStatus(record.status);
        localStorage.setItem("omsOrderDetails", JSON.stringify(omsOrdersList));
        setOmsOrderList([...newOMSOrderDetails]);
        nextOMSOrderStatus();
      }
    });
  };

  const handleOrderCard = (record) => {
    setSelectedOrder(record);
    handleOMSOrderStatusSelection(_.filter(omsOrderStatus, (statusItem) => statusItem.statusValue === record.status)?.[0]);
  };

  const handleOrderSearchInput = (value) => {
    if (value !== "") {
      const results = omsOrdersList.filter((orderDetails) => {
        return orderDetails.orderNo.startsWith(value);
      });
      setSearchOrders(results);
    } else {
      setSearchOrders(omsOrdersList);
    }
    setOrder(value);
  };

  const omsOrderTotalPrice = _.sumBy(selectedOrder.lineItems, "price");

  // OMS Orders Modal Ends //

  // Sales Representative Modal Starts //

  const [salesRepModalOpen, setSalesRepModalOpen] = useState({
    status: false,
    title: "",
  });
  const [salesRepresent, setSalesRepresent] = useState({});
  const [salesRepresentDefaultLine, setSalesRepresentDefaultLine] = useState({ salesRepresentId: null, name: null });

  const handleSalesRepresentive = (record) => {
    console.log("record", record, selectedProductInCart, salesRepModalOpen);
    if (salesRepModalOpen.title === "orderSalesRep") {
      setSalesRepresentDefaultLine({ ...record });
      setCart({
        ...cart,
        salesRepId: record.salesRepresentId,
      });
      setSalesRepresent(record);
    } else if (salesRepModalOpen.title === "itemSalesRep" && !_.isEmpty(selectedProductInCart)) {
      setSalesRepresentDefaultLine({ ...record });
      let lineItemsData = [...cart.items];
      const lineItemsIndex = _.findIndex(lineItemsData, (item) => item.productId === selectedProductInCart.productId);
      console.log("lineItemsIndex", lineItemsIndex);
      lineItemsData[lineItemsIndex]["salesRepId"] = record.salesRepresentId;
      lineItemsData[lineItemsIndex]["salesRepName"] = record.name;
      setCart({
        ...cart,
        items: lineItemsData,
      });
    }
    setSalesRepModalOpen({ status: false, title: "" });
  };

  const [salesRepresentSearchInput, setSalesRepresentSearchInput] = useState("");
  const salesReprestiveList = tillData.tillAccess.csBunit.salesRep;
  const [filteredSalesRepresentList, setFilteredSalesRepresentList] = useState(salesReprestiveList);

  const handleSalesRepresentSearchInput = (value) => {
    if (value !== "") {
      const results = salesReprestiveList.filter((list) => {
        return list.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setFilteredSalesRepresentList(results);
    } else {
      setFilteredSalesRepresentList(salesReprestiveList);
    }
    setSalesRepresentSearchInput(value);
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    // console.log("Key Press Event", e.key, altKey, keyCode);
    if (altKey) {
      if (keyCode === 83) {
        if (posConfig.showLineSalesRep === "Y") {
          setSalesRepModalOpen({ status: true, title: "itemSalesRep" });
          setSalesRepresentSearchInput("");
          setFilteredSalesRepresentList(salesReprestiveList);
        }
      }
      if (keyCode === 67) {
        setSelectedRowKeys((v) => {
          if (v.length > 0) {
            return [];
          } else {
            return [0];
          }
        });
      }
      if (keyCode === 80) {
        openPaymentModal();
      }

      if (keyCode === 79) {
        setDisplayOrderHistory(true);
      }

      if (keyCode === 66) {
        setDisplayParkedBillModal(true);
      }

      if (keyCode === 73) {
        if (selectedRowKeysRef.current.length > 0) {
          setIsQtyUpdate(selectedProductInCartRef.current);
        }
      }

      if (keyCode === 76) {
        parkBill("parkKey");
      }

      if (keyCode === 72) {
        confirmDiscardCart();
      }
    }

    if (keyCode === 115) {
      setTimeout(() => {
        productSearchInputRef.current.focus();
      }, 100);
    }
    if (keyCode === 27) {
      if ((e.target.id === "productSearchInputId" || e.target.id.search("productCardItem")) >= 0) {
        closeProductPanel();
      }
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
      setSalesRepModalOpen({ status: false, title: "" });
      setSalesRepresentSearchInput("");
      closeCustomerSearch();

      if (paymentModalStateRef.current) {
        closePaymentModal();
      }
    }
    if (keyCode === 38) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] > 0) {
          return [v[0] - 1];
        } else {
          return [v[0]];
        }
      });
    }
    if (keyCode === 40) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] < cartItemsLengthRef.current - 1) {
          return [v[0] + 1];
        } else {
          return [v[0]];
        }
      });
    }

    if (keyCode === 187 || keyCode === 107) {
      if (selectedRowKeysRef.current.length > 0) {
        increaseProductQty(selectedProductInCartRef.current);
      }
    }
    if (keyCode === 109 || keyCode === 189) {
      if (selectedRowKeysRef.current.length > 0) {
        if (parseFloat(selectedProductInCartRef.current.weight) > 1) {
          decreaseProductQty(selectedProductInCartRef.current);
        }
      }
    }
    if (keyCode === 46) {
      if (selectedRowKeysRef.current.length > 0) {
        deleteProduct(selectedProductInCartRef.current);
      }
    }
  };

  const cartItemsLengthRef = useRef(0);
  useEffect(() => {
    cartItemsLengthRef.current = cart.items.length;
  }, [cart]);

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
  }, [selectedProductInCart]);

  const selectedRowKeysRef = useRef([0]);
  useEffect(() => {
    selectedRowKeysRef.current = selectedRowKeys;
    if (selectedRowKeys.length > 0) {
      const cartIndex = cart.items.findIndex((ci) => ci.key === selectedRowKeys[0]);
      setSelectedProductInCart({ ...cart.items[cartIndex] });
    }
  }, [selectedRowKeys]);

  const paymentModalStateRef = useRef(false);
  useEffect(() => {
    paymentModalStateRef.current = paymentModal;
  }, [paymentModal]);

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  });

  // Sales Representative Modal Ends //

  const [manualDiscountModalVisible, setManualDiscountModalVisible] = useState(false);
  const [manualDiscountInput, setManualDiscountInput] = useState("");
  const [manualDiscountTypes, setManualDiscountTypes] = useState([]);
  const [selectedManualDiscountType, setSelectedManualDiscountTypeValue] = useState("");
  const [enableManualDiscountInput, setEnableManualDiscountInput] = useState(false);

  const handleManualDiscountKeyPress = (value) => {
    if (!enableManualDiscountInput) {
      if (manualDiscountInput === "" && value === "x") {
        setManualDiscountInput("");
      } else if (value === "x") {
        setManualDiscountInput(`${manualDiscountInput.toString().substring(0, manualDiscountInput.toString().length - 1)}`);
      } else {
        setManualDiscountInput(`${manualDiscountInput}${value}`);
      }
    }
  };

  useEffect(() => {
    db.pricingRules.toArray().then((pr) => {
      const manualPricingRules = pr.filter((rule) => rule.manualDiscount === "Y");
      setManualDiscountTypes([...manualPricingRules]);
    });
  }, []);

  const setSelectedManualDiscountType = (value) => {
    setSelectedManualDiscountTypeValue(value);
    if (value !== "") {
      const mdi = manualDiscountTypes.findIndex((md) => md.mPricingrulesId === value);
      const discountValue = manualDiscountTypes[mdi].amountDiscount ? manualDiscountTypes[mdi].amountDiscount : manualDiscountTypes[mdi].percentageDiscount;
      if (discountValue) {
        setManualDiscountInput(discountValue);
        setEnableManualDiscountInput(true);
      } else {
        setManualDiscountInput("");
        setEnableManualDiscountInput(false);
      }
    }
  };

  const applyManualDiscount = () => {
    if (selectedManualDiscountType && manualDiscountInput && cart.items.length > 0) {
      setManualDiscountModalVisible(false);
      if (selectedRowKeys.length > 0) {
        // Process line discount
        processLineManualDiscount();
      } else {
        // Process a total discount
        processTotalManualDiscount();
      }
    } else {
      message.warning("Please provide a valid input !");
    }
  };

  const processLineManualDiscount = () => {
    const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
    if (pricingRule.type === "FD") {
      const addedToCart = cart.items;
      const index = selectedRowKeys[0];
      if (index >= 0) {
        const discountAmt = parseFloat(manualDiscountInput);
        const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
        const mrp = parseFloat(sp) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].salePrice = sp;
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        addedToCart[index].discount = discountAmt * addedToCart[index].weight;
        addedToCart[index].discountName = pricingRule.printedName;
        addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }

    if (pricingRule.type === "PD") {
      const addedToCart = cart.items;
      const index = selectedRowKeys[0];
      if (index >= 0) {
        const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
        const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
        const mrp = parseFloat(sp) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].salePrice = sp;
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        addedToCart[index].discount = discountAmt * addedToCart[index].weight;
        addedToCart[index].discountName = pricingRule.printedName;
        addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
    }
    setSelectedRowKeys([]);
  };

  const processTotalManualDiscount = (discountParam) => {
    const billCart = { ...cart };
    const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
    if (pricingRule.type === "FD" || pricingRule.type === "PD") {
      let discountValue = 0;
      discountValue = pricingRule.type === "FD" ? parseFloat(manualDiscountInput) : (parseFloat(manualDiscountInput) / 100) * billCart.total;
      discountValue = discountParam ? parseFloat(discountParam) : discountValue;
      const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
      let salePricesTotal = 0;
      for (let i = 0; i < cartItemsFiltered.length; i += 1) {
        salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
      }

      billCart.items.map((addedToCart, index) => {
        if (addedToCart.nextRule === "Y" || addedToCart.nextRule === undefined) {
          // const productAmount = billCart.manualDiscountApplied ? addedToCart.realPrice : addedToCart.salePrice;
          const discountAmt = parseFloat((addedToCart.realPrice / salePricesTotal) * discountValue) / addedToCart.weight;
          const sp = parseFloat(addedToCart.realPrice) - discountAmt;
          const mrp = parseFloat(sp) * addedToCart.weight;
          const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
          addedToCart.salePrice = sp;
          addedToCart.taxAmount = tax;
          addedToCart.nettotal = mrp;
          addedToCart.discount = discountAmt * addedToCart.weight;
          addedToCart.discountName = pricingRule.printedName;
          addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
          billCart.items[index] = addedToCart;
        }
      });

      const addedToCart = billCart.items;

      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }

      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      totalPrice = roundOffValue;

      setCart({
        ...cart,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
        manualDiscountApplied: discountValue,
      });
    }
  };

  const removeAllDiscounts = () => {
    setManualDiscountModalVisible(false);
    setManualDiscountInput("");
    setSelectedManualDiscountTypeValue("");
    const cartItems = cart.items;

    cartItems.forEach((addedToCart, i) => {
      const sp = parseFloat(addedToCart.realPrice);
      const mrp = parseFloat(sp) * addedToCart.weight;
      const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
      addedToCart.salePrice = sp;
      addedToCart.taxAmount = tax;
      addedToCart.nettotal = mrp;
      addedToCart.discount = 0;
      cartItems[i] = addedToCart;
    });

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;

    for (let i = 0; i < cartItems.length; i += 1) {
      totalPrice += cartItems[i].nettotal;
      totalItemsQty += cartItems[i].weight;
      totalTax += cartItems[i].taxAmount;
      totalDiscounts += cartItems[i].discount;
      cartItems[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    delete cart["manualDiscountApplied"];
    
    setCart({
      ...cart,
      items: [...cartItems],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    });
  };

  const clearSelectedProductInCart = () => {
    setSelectedProductInCart({});
    setSelectedRowKeys([]);
  };

  const posLogActivity = (record, activity) => {
    console.log(record, activity);
    const timeMark = timeStamp();
    const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
    let products = "";
    if (_.isArray(record)) {
      record.forEach((item) => {
        products += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.salePrice}, `;
      });
    } else {
      products = `SKU: ${record.value}, Qty: ${record.weight}, Unit Price: ${record.salePrice}, `;
    }

    db.logInformation.add({
      type: activity,
      action: "LOG",
      description: activity === "DLN" || activity === "DOR" || activity === "RQT" || activity === "SLR" ? products : activity === "SRD" || activity === "ACT" ? record : "",
      date: currentDate,
      time: timeMark,
      orderNo: "",
      remarks: "",
    });
  };

  // Paytm QR Code Starts //

  const [paytmQrCodeModalOpens, setPaytmQrCodeModalOpens] = useState(false);
  const [qrCodeResponse, setQrCodeResponse] = useState({});

  const processQrPaytmPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    setPaytmQrCodeModalOpens(true);
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let paytmUrl = `${hostUrl}paytm/generateQRCode`;
    const getQRCodeValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      amount: amountAdded,
      businessType: "UPI_QR_CODE",
      posId: `${tillData.tillAccess.cwrTill.cwrTillID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payTMQRUrl: "https://securegw-stage.paytm.in/paymentservices/qr/create",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: paytmUrl,
      method: "POST",
      data: getQRCodeValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      console.log("response status", response.data);
      setQrCodeResponse(response.data);
    });
  };

  const handleVerifyPayment = () => {
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let verifyPaytmUrl = `${hostUrl}paytm/verifyPayment`;
    const getPaymentSuccessValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payUrlForVerify: "https://securegw-stage.paytm.in/v3/order/status",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: verifyPaytmUrl,
      method: "POST",
      data: getPaymentSuccessValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      console.log("response QR", response.data);
      if (response.data.body.resultInfo.resultStatus === "TXN_SUCCESS") {
        setPaytmQrCodeModalOpens(false);
        processPayment(selectedPaymentMethod, amount);
      }
    });
  };

  // Paytm QR Code Ends //

  const componentProps = {
    checkIsManualWeight,
    parkBill,
    openPaymentModal,
    addProduct,
    cart,
    setCart,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    onBarcodeInput,
    openDisplayParkedBillModal,
    orderType,
    parkedList,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editOldCustomer,
    displayOrderHistory,
    setDisplayOrderHistory,
    changeOrderHistorySearchType,
    searchOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    selectParkedBill,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    requestPayment,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    displaySetOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    setDefaultImage,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    setCouponInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    processOtpInput,
    loyaltyPaymentOtp,
    setLoyaltyPaymentOtp,
    currencyType,
    setCurrencyType,
    setDisplayClock,
    pickProduct,
    addDefinedProduct,
    handleWeightManual,
    // OMS Order MOdal and Side menu drawer//,
    openSideMenu,
    onClose,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    sideMenuDrawervisible,
    setDisplayOfferProductSelection,
    // Sales Representative Modal //
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresent,
    setSalesRepresent,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    salesReprestiveList,
    filteredSalesRepresentList,
    setFilteredSalesRepresentList,

    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    clearSelectedProductInCart,
    setProductWeightModalInput,
    productListCardRef,
    paymentModalByCustomerState,
    posLogActivity,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    setQrCodeResponse,
    handleVerifyPayment,
  };

  return (
    <div>
      <RenderComponent {...componentProps} />
      <CoreModals {...componentProps} />
      <ReturnBill {...componentProps} />
    </div>
  );
};
// PointOfSale Component End

export default PointOfsaleCore;
